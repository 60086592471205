
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'PDETag',

  components: { PDEIcon },

  props: {
    case: {
      type: String,
      default: '',
      validator: value => ['', 'capitalize', 'uppercase'].includes(value),
    },
    fill: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'error', 'highlight', 'success', 'warning'].includes(value),
    },
  },

  computed: {
    caseClass() {
      if (!this.case) return ''

      return {
        capitalize: 'capitalize',
        uppercase: 'uppercase',
      }[this.case]
    },

    fillClasses() {
      return {
        fill: {
          default: 'bg-pureblack-15 text-pureblack-75 border-pureblack-15',
          error: 'bg-error-50 text-error-900 border-error-50',
          highlight: 'bg-highlight-50 text-highlight-900 border-highlight-50',
          success: 'bg-success-50 text-success-900 border-success-50',
          warning: 'bg-warning-50 text-warning-900 border-warning-50',
        },
        line: {
          default: 'bg-transparent text-pureblack-75  border-pureblack-20',
          error: 'bg-transparent text-error-900  border-error-200',
          highlight: 'bg-transparent text-highlight-900  border-highlight-200',
          success: 'bg-transparent text-success-900  border-success-200',
          warning: 'bg-transparent text-warning-900  border-warning-200',
        },
      }[this.fill ? 'fill' : 'line'][this.type]
    },

    hasSlot() {
      return !!this.$slots.default
    },
  },
}

