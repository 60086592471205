
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'PDEAlert',

  components: {
    PDEIcon,
  },

  props: {
    icon: {
      type: String,
      default: '',
    },

    showIcon: {
      type: Boolean,
      default: true,
    },

    type: {
      type: String,
      default: 'success',
      validator: value => [
        'success',
        'info',
        'warning',
        'error',
      ].indexOf(value) !== -1,
    },

    id: {
      type: String,
      default: 'pde-alert',
    },

    display: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      visible: true,
    }
  },

  computed: {
    show() {
      return this.visible
    },

    alertClosedKey() {
      return `alert-closed-${this.id}`
    },

    colorClass() {
      return {
        success: 'text-success-400',
        info: 'text-black-800',
        warning: 'text-warning-700',
        error: 'text-error-400',
      }[this.type]
    },

    iconName() {
      if (this.icon) return this.icon

      return {
        error: 'close',
        info: 'plus',
        success: 'checkmark',
        warning: 'warning-fill',
      }[this.type]
    },
  },

  created() {
    const hasBeenClosed = localStorage.getItem(this.alertClosedKey) || false

    this.visible = !hasBeenClosed
  },
}
