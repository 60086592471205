
import { mapActions, mapState } from 'vuex'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEDocument from '@/components/pde/PDEDocument.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'DocumentsList',
  components: {
    PDEButton,
    PDEDocument,
    PDEHeading,
    PDEIcon,
  },
  props: {
    analyticsData: {
      type: Object,
      default: null,
    },

    documents: {
      type: Array,
      required: true,
    },

    highlightFirstRow: Boolean,

    shortlistLimit: {
      type: Number,
      default: 5,
    },

    showCategoryTitle: Boolean,
    showExpandButton: Boolean,

    // Passed through to PDEDocument
    showDocumentIcon: Boolean,
    showDownload: Boolean,
    showStatus: Boolean,
  },

  data() {
    return {
      showAllClicked: false,
    }
  },

  computed: {
    ...mapState('documents', ['myDocumentsViewedList']),

    allDocsShowing() {
      return !this.showExpandButton || this.showAllClicked
    },

    categorizedDocuments() {
      const categorized = this.$featureFlags.documentRedesign13008
        ? this.documents
          .filter((v, i) => this.allDocsShowing || i < this.shortlistLimit)
          .reduce((acc, doc) => {
            const category = doc.category || 'Other'
            if (!acc[category]) {
              acc[category] = []
            }
            acc[category].push(doc)
            return acc
          }, {})
        : { 'All Documents': this.documents }

      return Object.entries(categorized).sort(([a], [b]) => a.localeCompare(b))
    },
  },
  methods: {
    ...mapActions('analytics', ['sendClickEvent']),

    documentClicked(download) {
      if (this.analyticsData?.download && download) {
        this.sendClickEvent(this.analyticsData.download)
      }

      if (this.analyticsData?.open && !download) {
        this.sendClickEvent(this.analyticsData.open)
      }
    },

    handleViewAllClicked() {
      if (this.analyticsData?.expand) {
        this.sendClickEvent(
          this.analyticsData.expand
        )
      }
      this.showAllClicked = true
    },

    fontStyle(index, document) {
      if (!this.highlightFirstRow) {
        return undefined
      }
      return index === 0 && !this.myDocumentsViewedList.includes(document.dmsDocumentId) ? undefined : 'font-400'
    },
  },
}
